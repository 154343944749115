import { HttpStatus } from '../constant/responseStatus';
import { Session, SessionTimeOut } from '../constant/sesstion';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Axios from './Axios';
import { AccountService } from '../services/accountService';
import { ErrorType } from '../constant/errorType';
import alert, { alertType, Theme } from '../helper/alert';
import { ResponseSignIn, SignInModel, SignInResponseModel } from '../models/accountModel';
import sessionStore from '../store/sessionStore';

const refreshTokenAsync = async (error?: AxiosError) => {
  const token = sessionStore.get(Session.SESSION_ACCESS_TOKEN) as SignInResponseModel;
  const refreshToken = sessionStore.get(Session.SESSION_REFRESH_TOKEN);

  if (!token) {
    signOut();

    return null;
  }

  const response = await axios({
    baseURL: `${process.env.REACT_APP_API_PATH}/account/refresh-token`,
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data: {
      refreshToken: refreshToken,
      clientId: 'VFun-Web',
    },
  });

  if (response.status !== HttpStatus.OK) {
    signOut();

    return null;
  }

  const signInResponse = {
    access_token: response.data.access_token,
    expires_in: response.data.expires_in,
    token_type: response.data.token_type,
  } as SignInResponseModel;

  const expireMillisecond = signInResponse.expires_in * 1000;

  sessionStore.set(Session.SESSION_ACCESS_TOKEN, signInResponse, expireMillisecond);
  sessionStore.set(Session.SESSION_REFRESH_TOKEN, response.data.refresh_token, SessionTimeOut.ONE_WEEK);
  sessionStore.set(Session.EXPIRE_SESSION, calculationTokenExpire(token.expires_in), SessionTimeOut.ONE_WEEK);

  if (error) {
    // Todo: Handle error response
    Axios(error.config as any);
  }

  return signInResponse;
};

const signInAsync = async (signinModel: SignInModel) => {
  const accountService = new AccountService();
  const response = await accountService.signInAsync(signinModel.username, signinModel.password);

  return await setSignInAsync(response);
};

const setSignInAsync = async (response: AxiosResponse) => {
  if (response.status === HttpStatus.NOT_FOUND) {
    alert.showAsync({ message: 'User not found in the system Please register before starting to use.', type: alertType.ERROR, theme: Theme.DARK });

    return { type: ErrorType.ERROR, msg: 'User not found in the system Please register before starting to use.', status: response.status } as ResponseSignIn;
  }

  if (response.status === HttpStatus.BAD_REQUEST) {
    const errorDesc = response?.data?.error_description;

    if (errorDesc && errorDesc === 'Device code missing.') {
      return { type: ErrorType.ERROR_2FA, msg: response?.data?.error_description, status: response.status } as ResponseSignIn;
    }

    alert.showAsync({ message: 'Invalid user or incorrect password.', type: alertType.ERROR, theme: Theme.DARK, buttonText: 'Ok' });

    return { type: ErrorType.ERROR, msg: 'Invalid user or incorrect password.', status: response.status } as ResponseSignIn;
  }

  if (response.status === HttpStatus.FORBIDDEN) {
    alert.showAsync({ message: 'The user does not have permission to log in.', type: alertType.ERROR, theme: Theme.DARK, buttonText: 'Ok' });

    return { type: ErrorType.ERROR, msg: 'The user does not have permission to log in.', status: response.status } as ResponseSignIn;
  }

  if (response && response.status !== HttpStatus.OK) {
    alert.showAsync({ message: 'Login Failed', type: alertType.ERROR, theme: Theme.DARK, buttonText: 'Ok' });

    return { type: ErrorType.ERROR, msg: 'Login Failed', status: response.status } as ResponseSignIn;
  }

  const signInResponse = {
    access_token: response.data.access_token,
    expires_in: response.data.expires_in,
    token_type: response.data.token_type,
  } as SignInResponseModel;

  const expireMillisecond = signInResponse.expires_in * 1000;

  sessionStore.set(Session.SESSION_ACCESS_TOKEN, signInResponse, expireMillisecond);
  sessionStore.set(Session.SESSION_REFRESH_TOKEN, response.data.refresh_token, SessionTimeOut.ONE_WEEK);
  sessionStore.set(Session.EXPIRE_SESSION, calculationTokenExpire(signInResponse.expires_in), SessionTimeOut.ONE_WEEK);

  return { type: alertType.SUCCESS, status: response.status, data: response.data as SignInResponseModel } as ResponseSignIn;
};

const calculationTokenExpire = (expireIn: number) => {
  return Date.now() + expireIn * 1000;
};

const signOut = (routeTo: string = '/') => {
  sessionStore.remove(Session.SESSION_ACCESS_TOKEN);
  sessionStore.remove(Session.SESSION_REFRESH_TOKEN);
  sessionStore.remove(Session.SESSION_USER);
  sessionStore.remove(Session.SESSION_HOST);
  sessionStore.remove(Session.EXPIRE_SESSION);

  if (routeTo) {
    window.location.href = routeTo;
  }

  //FIXME: Change 'window.location.href' to use route dom function
  window.location.href = '/';
};

export default {
  refreshTokenAsync,
  signInAsync,
  signOut,
};