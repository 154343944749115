import { Card, Row, Col, Form, Divider, Space, Tag, Button, Input, Modal, message } from 'antd';
import { useState, useEffect } from 'react';
import { MasterDataService } from '../../services/masterDataService';
import { MasterDataType } from '../../constant/masterDataConstants';
import { HttpStatus } from '../../constant/responseStatus';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { MasterDataResponse } from '../../models/response/MasterDataResponse';

export default function Keywords() {
  const [form] = Form.useForm();
  const [keywords, setKeywords] = useState<MasterDataResponse[]>([]);
  const { confirm, error } = Modal;

  useEffect(() => {
    onGetListKeywords();
  }, []);

  const onGetListKeywords = async () => {
    const { data, status } = await new MasterDataService().getMasterData(MasterDataType.KEYWORD);

    if (status !== HttpStatus.OK) {
      return;
    }

    setKeywords(data);
  };

  const onFinish = () => {
    const newKeyword: string = form.getFieldValue('keyword');

    const isKeywordDuplicate =
      keywords
        .filter((item) => item.name.trim().toUpperCase() === newKeyword.trim().toUpperCase())
        .length > 0;

    if (isKeywordDuplicate) {
      error({
        title: ` Duplicate keyword.`,
        icon: <ExclamationCircleFilled />,
      });

      return;
    }

    onCreate(newKeyword);
  };

  const onCreate = async (keyword: string) => {
    const { status } =
      await new MasterDataService().createMasterData(
        MasterDataType.KEYWORD,
        keyword);

    if (status !== HttpStatus.CREATED) {
      message.error('Save keyword failed.');
      return;
    }

    message.success('Save keyword success.');
    form.resetFields(['keyword']);

    onGetListKeywords();
  };

  const onDelete = (id: string, name: string) => {
    confirm({
      title: `Do you want to delete keyword : ${name} ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onDeleteKeyword(id);
      },
      onCancel() {
        onGetListKeywords();
      },
    });
  };

  const onDeleteKeyword = async (id: string) => {
    const { status } = await new MasterDataService().deleteMasterData(id);

    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete keyword failed.');
      return;
    }

    message.success('Delete keyword success.');
    onGetListKeywords();
  };

  return (
    <>
      <Card
        className='card-customize'
        title='Keyword'
      >
        <div
          className='p-4'
          style={{ minHeight: 700 }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout='vertical'
            autoComplete='off'
          >
            <Row>
              <Col span={8}>
                <Form.Item
                  name='keyword'
                  className=' text-light w-100'
                  label='Keyword'
                  rules={[{ required: true, message: 'Please input new keywords!' }]}
                >
                  <Space direction='horizontal'>
                    <Input placeholder='Keyword' size='large' />
                    <Button
                      type='primary'
                      className='px-5'
                      htmlType='submit'
                      size='large'
                    >
                      Add
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col
                span={24}
                className='text-right'
              >
                <Space></Space>
              </Col>
            </Row>
          </Form>
          <Divider
            orientation='left'
            plain
          >
            Recommend Keywords
          </Divider>
          <Space
            size={[0, 8]}
            wrap
          >
            {keywords &&
              keywords.length > 0 &&
              keywords.sort((a, b) => a.name.localeCompare(b.name)).map((d, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    onDelete(d.value, d.name);
                  }}
                >
                  {d.name}
                </Tag>
              ))}
          </Space>
        </div>
      </Card>
    </>
  );
}
