import {
  Card,
  Row,
  Col,
  Form,
  Space,
  Typography,
  Divider,
  Button,
  Input,
  Modal,
  message,
} from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { CollectionService } from '../../services/collectionService';
import {
  CollectionAssetCreatingModel,
  CollectionAssetItemCreatingModel,
  CollectionItemModel,
  CollectionModel,
} from '../../models/CollectionModel';
import { AccountService } from '../../services/accountService';
import { ArtistProfileModel } from '../../models/accountModel';
import GalleryItem from '../../components/galleryItem/GalleryItem';
import Title from 'antd/es/typography/Title';
import { AssetService } from '../../services/assetService';
import { StringConstants } from '../../constant/stringConstants';
import GalleryDragItem from '../../components/galleryItem/GalleryDragItem';
import update from 'immutability-helper';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default function CollectionDetail() {
  const [form] = Form.useForm();
  const [artist, setArtist] = useState<ArtistProfileModel>({} as ArtistProfileModel);
  const [collection, setCollection] = useState<CollectionModel>({} as CollectionModel);
  const [collectionItems, setCollectionItems] = useState<CollectionItemModel[]>([]);
  const { confirm } = Modal;
  const [searchParams] = useSearchParams();
  const [collectionId, setCollectionId] = useState('');
  const [isArtistCollection, setIsArtistCollection] = useState(false);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setCollectionId(id);
      onGetCollection(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (collection.userId) {
      onGetArtist(collection.userId);
    }
  }, [collection]);

  const onGetCollection = async (id: string) => {
    const { data, status } = await new CollectionService().getIdAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    form.setFieldValue('collectionName', data.name);
    setCollection(data);
    setCollectionItems(data.collectionItems.sort((a, b) => a.coverSeq! - b.coverSeq!));
  };

  const onGetArtist = async (id: string) => {
    const { data, status } = await new AccountService().getArtistById(id);

    if (status !== HttpStatus.OK) {
      setIsArtistCollection(false);
      return;
    }

    setIsArtistCollection(true);
    setArtist(data);
  };

  const redirectToAssetDetail = (assetId: string) => {
    window.location.href = `${RouteNames.assetDetail}?id=${assetId}`;
  };

  const onClickCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    message.success('Copy to clipboard');
  };

  const onFinish = async (value: any) => {
    let updItem: CollectionItemModel[] = [];
    if (collection.id) {
      for (let index = 0; index < collectionItems.length; index++) {
        let newItem: CollectionItemModel = {
          assetId: collectionItems[index].assetId,
          coverSeq: index + 1,
          id: collectionItems[index].id,
          asset: collectionItems[index].asset,
        };

        updItem.push(newItem);
      }

      const updCollection: CollectionModel = {
        id: collection.id,
        userId: collection.userId,
        name: form.getFieldValue('collectionName'),
        collectionItems: updItem,
      };

      const { status } = await new CollectionService().UpdateCollection(updCollection);

      if (status !== HttpStatus.OK) {
        message.error('save collection failed');

        return;
      }

      message.success('save collection success');
      window.location.href = `${RouteNames.collectionDetail}?id=${collection.id}`;
    } else {
      const newCollection: CollectionAssetCreatingModel = {
        name: form.getFieldValue('collectionName'),
        collectionItems: updItem,
      };

      const { data, status } = await new CollectionService().CreateCollection(newCollection);

      if (status !== HttpStatus.OK) {
        message.error('save collection failed');
        return;
      }

      message.success('save collection success');
      window.location.href = `${RouteNames.collectionDetail}?id=${data.id}`;
    }
  };

  const onAddItem = async () => {
    const assetCode: string = form.getFieldValue('assetCode');
    if (assetCode && collection) {
      if (collection.collectionItems.filter((x) => x.asset.code === assetCode).length > 0) {
        return;
      }
      const { data, status } = await new AssetService().getAssetByCode(assetCode);

      if (status !== HttpStatus.OK) {
        message.error('Add item failed.');
        return;
      }

      const itemData = {
        assetId: data.id,
      } as CollectionAssetItemCreatingModel;

      const response = await new CollectionService().addCollectionItem(collection.id, itemData);

      if (response.status !== HttpStatus.CREATED) {
        message.open({
          type: 'error',
          content: 'Add collection failed.',
        });

        return;
      }

      message.open({
        type: 'success',
        content: 'Add collection success.',
      });

      form.setFieldValue('assetCode', StringConstants.EMPTY);
      onGetCollection(collection.id);
    }
  };

  const onDeleteItem = async (itemId: string) => {
    const { status } = await new CollectionService().DeleteCollectionItem(collection.id, itemId);

    if (status !== HttpStatus.OK) {
      message.error('Delete item failed.');
      return;
    }
    message.success('Delete item success.');
    onGetCollection(collection.id);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    console.log('drag');
    setCollectionItems((prevCards: CollectionItemModel[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as CollectionItemModel],
        ],
      })
    );
  }, []);

  const Delete = (id: string) => {
    confirm({
      title: `Do you want to delete ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onDeleteCollection(id);
      },
    });
  };

  const onDeleteCollection = async (id: string) => {
    const { status } = await new CollectionService().DeleteCollection(id);
    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete collection failed.');
      return;
    }
    message.success('Delete collection success.');
    window.location.href = `${RouteNames.collection}`;
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout='horizontal'
        autoComplete='off'
      >
        <Card
          className='card-customize'
          title={
            <Space align='center'>
              <Typography.Title
                level={5}
                className='mb-0'
              >
                Collection Detail
              </Typography.Title>
            </Space>
          }
          extra={
            !isArtistCollection && (
              <Space align='center'>
                {
                  collectionId &&
                  <Button
                    type='primary'
                    danger
                    onClick={() => Delete(collectionId)}
                  >
                    Delete
                  </Button>
                }
                <Button
                  type='primary'
                  htmlType='submit'
                >
                  Save
                </Button>
              </Space>
            )
          }
        >
          <div className='p-4'>
            {collection.id && (
              <Row gutter={16}>
                <Col span={4}>Collection ID</Col>
                <Col span={20}>
                  : <Text code>{collection.id}</Text>
                  <Button
                    size='small'
                    onClick={() => onClickCopy(collection.id)}
                  >
                    copy
                  </Button>
                </Col>
              </Row>
            )}
            <Row
              gutter={16}
              className='mt-4'
            >
              <Col span={4}>Collection Name</Col>
              <Col span={6}>
                <Form.Item
                  name='collectionName'
                  className='text-light w-100'
                  rules={[{ required: true, message: 'Please input your subject!' }]}
                >
                  <Input disabled={isArtistCollection} />
                </Form.Item>
              </Col>
            </Row>
            {artist && artist.artist && (
              <>
                <Row gutter={16}>
                  <Col span={4}>Artist Code</Col>
                  <Col span={20}>
                    : <Link to={`${RouteNames.artistDetail}/${artist.artist.id}`}>{artist.artist.code}</Link>
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className='mt-4'
                >
                  <Col span={4}>Artist Email</Col>
                  <Col span={20}>
                    : <Text>{artist.artist.email}</Text>
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className='mt-4'
                >
                  <Col span={4}>Artist Name</Col>
                  <Col span={20}>
                    : <Text>{artist.artist.nameInfoEn.firstname + ' ' + artist.artist.nameInfoEn.lastname}</Text>
                  </Col>
                </Row>
              </>
            )}
            <Divider orientation='left'>Asset List</Divider>
            {!isArtistCollection && collection.id !== undefined && (
              <>
                <Row>
                  <Col style={{ paddingTop: 5, paddingRight: 5 }}>
                    <Title level={5}>Asset Code : </Title>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='assetCode'
                      className='text-light w-100'
                      rules={[{ required: false }]}
                    >
                      <Input
                        className='w-100'
                        size='large'
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      type='primary'
                      onClick={() => onAddItem()}
                      size='large'
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            <div className='collection'>
              <Row gutter={16}>
                {collectionItems &&
                  collectionItems.map((data, index) => (
                    <Col key={data.asset.id}>
                      {isArtistCollection ? (
                        <GalleryItem
                          index={index}
                          asset={data.asset}
                          onClickItem={redirectToAssetDetail}
                          width='120px'
                          height='120px'
                          btnDelmarginRight={20}
                        />
                      ) : (
                        <GalleryDragItem
                          key={index}
                          index={index}
                          asset={data.asset}
                          id={data.id}
                          width={'200px'}
                          height={'200px'}
                          onClickItem={redirectToAssetDetail}
                          btnDelmarginTop={10}
                          btnDelmarginRight={20}
                          onDelete={onDeleteItem}
                          moveCard={moveCard}
                        />
                      )}
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </Card>
      </Form>
    </>
  );
}
