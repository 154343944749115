import {
  Card,
  Row,
  Col,
  Form,
  Space,
  Typography,
  Divider,
  Upload,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
} from 'antd';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from '../../constant/uploadFileConstants';
import { StringConstants } from '../../constant/stringConstants';
import { S3Constants } from '../../constant/s3Constants';
import AWS from 'aws-sdk';
import {
  BannerCreatingModel,
  BannerItemCreatingModel,
  BannerItemModel,
  BannerModel,
  BannerlistCropModel,
} from '../../models/bannerModel';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { BannerItemType } from '../../constant/BannerConstants';

AWS.config.update({
  accessKeyId: S3Constants.ACCESS_KEY_ID,
  secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
});

const S3_BUCKET = S3Constants.BUCKET;
const REGION = S3Constants.REGION;
const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function BannerDetail() {
  const [form] = Form.useForm();
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
  const [fileImageList, setFileImageList] = useState<UploadFile[]>([]);
  const [fileVideoList, setFileVideoList] = useState<UploadFile[]>([]);
  const [itemCrops, setItemCrops] = useState<BannerlistCropModel[]>([]);
  const [bannerItemsCreate, setBannerItemsCreate] = useState<BannerItemCreatingModel[]>([]);
  const [bannerItemsRemove, setBannerItemsRemove] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const [bannerId, setBannerId] = useState('');
  const [banner, setBanner] = useState<BannerModel>({} as BannerModel);
  const { confirm } = Modal;
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');

    if (id) {
      setBannerId(id);
      onGetBanner(id);
    }
  }, [searchParams]);

  const onGetBanner = async (id: string) => {
    const { data, status } = await new BannerService().getId(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setBanner(data);
    setBannerItemsRemove([]);
    form.setFieldValue('name', data.name);
    form.setFieldValue('startDate', dayjs(data.startDate));

    if (data.endDate) {
      form.setFieldValue('endDate', dayjs(data.endDate));
    }

    setBannerId(data.id);

    if (data.bannerItems?.length > 0) {
      const mapBannerItemsToFileData = (items: BannerItemModel[]): UploadFile[] =>
        items.map(item => ({
          uid: item.id!,
          name: item.name,
          status: 'done',
          url: item.mediaUrl,
          thumbUrl: item.mediaUrl,
        }));

      const filterByType = (items: BannerItemModel[], type: BannerItemType) =>
        items.filter(item => item.type === type);

      const bannerItemImages = mapBannerItemsToFileData(
        filterByType(data.bannerItems, BannerItemType.IMAGE)
      );
      setFileImageList(bannerItemImages);

      const bannerItemVideos = mapBannerItemsToFileData(
        filterByType(data.bannerItems, BannerItemType.VIDEO)
      );
      setFileVideoList(bannerItemVideos);
    }
  };

  const onImageChange: UploadProps['onChange'] = ({ fileList: newImageFileList }) => {
    setFileImageList(newImageFileList);
  };

  const onVideoChange: UploadProps['onChange'] = ({ fileList: newVideoFileList }) => {
    setFileVideoList(newVideoFileList);
  };

  const beforeUpload = async (file: UploadFile) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG or PNG file!');
      return false;
    } else {
      return true;
    }
  };

  const onPreviewImage = async (file: UploadFile) => {
    let src = file.url as string;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    imgWindow?.document.write(image.outerHTML);
  };

  const uploadVideoProps: UploadProps = {
    listType: UploadListType.PICTURE,
    accept: UploadFileConstants.BANNER_ITEM_MIME_VIDEO_ONLY.toString(),
    onChange: onVideoChange,
    fileList: fileVideoList,
    async onRemove(fileRemove) {
      if (fileRemove.url != null) {
        setBannerItemsRemove([...bannerItemsRemove, fileRemove.url]);
      }

      if (fileRemove.response != null) {
        setBannerItemsRemove([...bannerItemsRemove, fileRemove.response]);
      }

      if (bannerItemsCreate && bannerItemsCreate.length > 0) {
        setBannerItemsCreate(
          bannerItemsCreate
            .filter((bannerItem) => bannerItem.mediaUrl != fileRemove.url));
      }


    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(
        originalFile,
        BannerItemType.VIDEO,
        onError,
        onSuccess,
        onProgress);
    },
  };

  const uploadImageProps: UploadProps = {
    listType: UploadListType.PICTURE_CARD,
    accept: UploadFileConstants.BANNER_ITEM_MIME_IMAGE_ONLY.toString(),
    fileList: fileImageList,
    onChange: onImageChange,
    onPreview: onPreviewImage,
    async onRemove(fileRemove) {
      if (fileRemove.url != null) {
        setBannerItemsRemove([...bannerItemsRemove, fileRemove.url]);
      }

      if (fileRemove.response != null) {
        setBannerItemsRemove([...bannerItemsRemove, fileRemove.response]);
      }

      if (bannerItemsCreate && bannerItemsCreate.length > 0) {
        setBannerItemsCreate(
          bannerItemsCreate
            .filter((bannerItem) => bannerItem.mediaUrl != fileRemove.url));
      }
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(
        originalFile,
        BannerItemType.IMAGE,
        onError,
        onSuccess,
        onProgress);
    },
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const addFileToS3 = async (
    originalFile: any,
    fileType: string,
    onError: any,
    onSuccess: any,
    onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    const newBannerItme: BannerItemCreatingModel = {
      mediaUrl: filePath,
      type: fileType,
      name: originalFile.name,
    };

    setBannerItemsCreate(
      [
        ...bannerItemsCreate,
        newBannerItme
      ]);

    setUploadFileName(fileName);
    setItemCrops([...itemCrops, { OriginalName: originalFile.name, MediaUrl: filePath }]);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      StringConstants.UNDERSCORE +
      'banner' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const onFinish = (value: any) => {
    if (bannerItemsRemove.length > 0) {
      bannerItemsRemove.forEach(async (itemRemoveUrl) => {
        await deleteFileFromS3(itemRemoveUrl);
      })
    }

    let startDate;
    let endDate;

    startDate = new Date(value.startDate);

    if (value.endDate) {
      endDate = new Date(value.endDate);
    }

    const request: BannerCreatingModel = {
      name: value.name,
      startDate: startDate,
      endDate: endDate,
      bannerItems: bannerItemsCreate,
    };

    if (!(bannerId && banner)) {
      onCreate(request);

      return;
    }

    let currentBannerItem: BannerItemModel[] =
      banner.bannerItems
        .map((data) => ({
          id: data.id,
          mediaUrl: data.mediaUrl,
          type: data.type,
          name: data.name,
        }));

    let newBannerItem: BannerItemModel[] =
      bannerItemsCreate
        .filter(i => i.mediaUrl)
        .map((data) => ({
          id: undefined,
          mediaUrl: data.mediaUrl,
          type: data.type,
          name: data.name,
        }));

    newBannerItem = newBannerItem.filter(
      (newItem) => !currentBannerItem.some(
        (existingItem) =>
          existingItem.mediaUrl === newItem.mediaUrl)
    );

    const bannerItemUpdate = [...currentBannerItem, ...newBannerItem].filter(
      (item) => !bannerItemsRemove.some((removeItemUrl) =>
        removeItemUrl == item.mediaUrl ||
        item.mediaUrl.includes(removeItemUrl))
    );

    let bannerUpdate: BannerModel = {
      id: bannerId,
      name: value.name,
      startDate: startDate,
      endDate: endDate,
      bannerItems: bannerItemUpdate,
    };

    onUpdate(bannerUpdate);
  };

  const onCreate = async (request: BannerCreatingModel) => {
    const { data, status } = await new BannerService().createBanner(request);

    if (status !== HttpStatus.OK) {
      message.error('Save banner failed.');
      return;
    }

    message.success('Save banner success.');
    navigate(`${RouteNames.bannerDetail}?id=${data.id}`);
  };

  const onUpdate = async (request: BannerModel) => {
    const { data, status } = await new BannerService().updateBanner(bannerId, request);

    if (status !== HttpStatus.OK) {
      message.error('Save banner failed.');

      return;
    }

    await handleBannerItemsRemoval(bannerId, banner.bannerItems);

    message.success('Save banner success.');
    onGetBanner(data.id);
  };

  const handleBannerItemsRemoval = async (
    bannerId: string,
    bannerItemsExisting: BannerItemModel[]) => {
    if (banner.bannerItems.length === 0 || bannerItemsRemove.length === 0) {
      return;
    }

    const itemsToRemove = bannerItemsExisting.filter(item =>
      bannerItemsRemove.some(removeUrl =>
        item.id && item.mediaUrl.trim() === removeUrl.trim()
      )
    );

    if (itemsToRemove.length > 0) {
      const bannerService = new BannerService();

      for (const item of itemsToRemove) {
        await bannerService.deleteBannerItem(bannerId, item.id!);
      }
    }
  };

  const Delete = (id: string) => {
    confirm({
      title: `Do you want to delete?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onDelete(id);
      },
    });
  };

  const onDelete = async (id: string) => {
    const { status } = await new BannerService().deleteBanner(id);

    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete banner failed.');
      return;
    }

    message.success('Delete banner success.');
    navigate(RouteNames.banner);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
      >
        <Card
          className='card-customize'
          title={
            <Space align='center'>
              <Typography.Title
                level={5}
                className='mb-0'
              >
                Banner Detail
              </Typography.Title>
            </Space>
          }
          extra={
            <Space align='center'>
              {
                bannerId &&
                <Button
                  type='primary'
                  danger
                  onClick={() => Delete(bannerId)}
                >
                  Delete
                </Button>
              }

              <Button
                type='primary'
                htmlType='submit'
              >
                Save
              </Button>
            </Space>
          }
        >
          <div className='p-4'>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={12}
              >
                <Form.Item
                  name='name'
                  className='text-light w-100'
                  label='Banner Name'
                  rules={[{ required: true, message: 'Please input your subject!' }]}
                >
                  <Input className='w-100' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='startDate'
                  className='text-light w-100'
                  label='Start Date'
                  rules={[{ required: true, message: 'Please input your subject!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                    onChange={(dateValue) => form.setFieldsValue({ endDate: null })}
                    disabledDate={(current) => {
                      return current && current.isBefore(dayjs().startOf('day'));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='endDate'
                  className='text-light w-100'
                  label='End Date'
                  rules={[{ required: false, message: 'Please input your subject!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                    disabledDate={(current) => {
                      var startDate = form.getFieldValue('startDate');
                      return current && current.isBefore(dayjs(startDate).startOf('day'));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider
              orientation='left'
              orientationMargin='0px'
            >
              Image Upload (Ratio 10:4)
            </Divider>
            <Row gutter={20}>
              <Col
                className='gutter-row'
                span={24}
              >
                <ImgCrop
                  rotationSlider
                  showGrid={false}
                  aspect={10 / 4}
                  beforeCrop={beforeUpload}
                  quality={1}
                >
                  <Upload
                    {...uploadImageProps}
                  >
                    {fileImageList.length < 10 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </Col>
            </Row>
            <Divider
              orientation='left'
              orientationMargin='0px'
            >
              Video Upload (Ratio 10:4)
            </Divider>
            <Row gutter={20}>
              <Col
                className='gutter-row'
                span={24}
              >
                <Upload
                  {...uploadVideoProps}>
                  <Button icon={<UploadOutlined />}>Click to Upload Video</Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </>
  );
}
