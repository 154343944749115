export class Constants {
  static readonly stringEmpty: string = '';
  static readonly stringHyphen: string = '-';
}

export const enum CustomerTab {
  Individual = 'Individual',
  Company = 'Company',
  Artist = 'Artist',
  TaxCenter = 'TaxCenter',
}

export const enum DateFormat {
  DateShortMonth = 'DD MMM YYYY',
  DateTime = 'DD/MM/YYYY HH:mm:ss',
  DateTimeDash = 'DD-MM-YYYY HH:mm',
  DateTimeWithOutTime = 'DD-MM-YYYY',
};

export class RegexPattern {
  static readonly password: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/;
}