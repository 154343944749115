export const enum UploadFileConstants {
  MIME_ACCEPT = `image/jpeg, image/heic, image/heif, video/mp4, video/quicktime, video/mp4; codecs="dvhe", video/hevc`,
  MIME_RELEASES_ACCEPT = `image/jpeg, image/heic, image/heif, application/pdf`,
  MIME_ACCEPT_IMAGE = 'image/jpg, image/jpeg, image/png, image/heic, image/heif,',
  IMAGE_MIME_JPG = 'image/jpeg',
  IMAGE_FORMAT_JPG = '.jpg',
  IMAGE_FORMAT_HEIC = '.heic',
  FILE_NAME_PREFIX = 'alive_',
  FILE_NAME_PREFIX_PROFILE = 'alive_profile',
  MAX_FILE_SIZE = 52428800, // 50MB
  IMAGE_MIN_WIDTH = 3000,
  IMAGE_MIN_HEIGHT = 2000,
  IMAGE_MIN_DPI = 72,
  VIDEO_MIN_DURATION = 5,
  VIDEO_MAX_DURATION = 60,
  VIDEO_MIN_RESOLUTION_WIDTH = 1280,
  VIDEO_MIN_RESOLUTION_HEIGHT = 720,
  MAX_UPLOAD_PER_TIME = 50,
  MAX_UPLOAD_PER_WEEK = 500,
  BANNER_ITEM_MIME_IMAGE_ONLY = `image/jpeg, image/heic, image/heif`,
  BANNER_ITEM_MIME_VIDEO_ONLY = `video/mp4, video/quicktime, video/mp4; codecs="dvhe", video/hevc`,
};

export const enum UploadValidationMessages {
  INVALID_FILE_TYPE = "Invalid file type.",
  FILE_ALREADY_EXISTS = "File already exists.",
  IMAGE_MIN_RESOLUTION = "Minimum 6 Megapixel resolution (approximately 3000x2000).",
  IMAGE_MAX_FILE_SIZE = "Max size files is 50 Megabytes.",
}

export const enum MIMETYPE {
  ALL_VIDEO = "video.*",
  ALL_IMAGE = "image.*"
}

export const enum ExifKey {
  WIDTH = "ExifImageWidth",
  HEIGHT = "ExifImageHeight",
  X_RESOLUTION = "XResolution",
  Y_RESOLUTION = "YResolution",
}

export const enum UploadListType {
  TEXT = "text",
  PICTURE = "picture",
  PICTURE_CARD = "picture-card"
}