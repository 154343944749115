import { Card, Row, Col, Form, Space, Typography, Divider, Button, Input, DatePicker, Modal, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  RecommendAssetCreatingModel,
  RecommendAssetItemModel,
  RecommendAssetModel,
} from '../../models/recommendAssetModel';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { AssetService } from '../../services/assetService';
import { RecommendAssetService } from '../../services/recommendAssetService';
import { RouteNames } from '../../components/Route/routeName';
import dayjs from 'dayjs';
import GalleryItem from '../../components/galleryItem/GalleryItem';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { StringConstants } from '../../constant/stringConstants';
import Title from 'antd/es/typography/Title';

export default function RecommendDetail() {
  const [form] = Form.useForm();
  const [recommendAssetItems, setRecommendAssetItems] = useState<RecommendAssetItemModel[]>([]);
  const { confirm, } = Modal;
  const [searchParams,] = useSearchParams();
  const [recommentAssetId, setRecommentAssetId] = useState('');
  const [, setRecommentAsset] = useState<RecommendAssetModel>({} as RecommendAssetModel);
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setRecommentAssetId(id);
      onGetRecommentAsset(id);
    }
  }, [searchParams]);

  const onGetRecommentAsset = async (id: string) => {
    const { data, status } = await new RecommendAssetService().getId(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setRecommentAsset(data);
    setRecommendAssetItems(data.recommendAssetItems);
    form.setFieldValue('name', data.name);
    form.setFieldValue('startDate', dayjs(data.startDate));
    if (data.endDate) {
      form.setFieldValue('endDate', dayjs(data.endDate));
    }
    setRecommentAssetId(data.id);
  };

  const onAddItem = async () => {
    var assetCode: string = form.getFieldValue('assetCode');

    if (assetCode) {
      assetCode = assetCode.trim();

      if (recommendAssetItems
        .filter((assetItem) => assetItem.assetCode === assetCode)
        .length > 0) {
        return;
      }

      const { data, status } = await new AssetService().getAssetByCode(assetCode);

      if (status !== HttpStatus.OK) {
        message.error('Add item failed.');
        return;
      }

      message.success('Add item success.');
      form.setFieldValue('assetCode', StringConstants.EMPTY);
      setRecommendAssetItems([
        ...recommendAssetItems,
        { id: undefined, assetId: data.id, assetCode: assetCode, asset: data },
      ]);
    }
  };

  const onFinish = (value: any) => {
    let startDate;
    let endDate;
    if (value.startDate) {
      startDate = new Date(value.startDate);
    }

    if (value.endDate) {
      endDate = new Date(value.endDate);
    }

    const request: RecommendAssetCreatingModel = {
      name: value.name,
      startDate: new Date(value.startDate),
      endDate: endDate,
      recommendAssetItems: recommendAssetItems,
    };

    if (recommentAssetId) {
      onUpdate(request);
    } else {
      onCreate(request);
    }
  };

  const onCreate = async (request: RecommendAssetCreatingModel) => {
    const { data, status } = await new RecommendAssetService().createRecommendAsset(request);

    if (status !== HttpStatus.CREATED) {
      message.error('Save recommend asset failed.');
      return;
    }

    message.success('Save recommend asset success.');
    navigate(`${RouteNames.recommendAssetDetail}?id=${data.id}`);
  };

  const onUpdate = async (request: RecommendAssetCreatingModel) => {
    const { data, status } = await new RecommendAssetService().updateRecommendAsset(recommentAssetId, request);

    if (status !== HttpStatus.OK) {
      message.error('Save recommend asset failed.');
      return;
    }

    message.success('Save recommend asset success.');
    onGetRecommentAsset(data.id);
  };

  const Delete = (id: string) => {
    confirm({
      title: `Do you want to delete ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onDeleteRecommentAsset(id);
      },
    });
  };

  const onDeleteRecommentAsset = async (id: string) => {
    const { status } = await new RecommendAssetService().deleteRecommendAsset(id);

    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete recommend asset failed.');
      return;
    }

    message.success('Delete recommend asset success.');
    window.location.href = `${RouteNames.recommendAsset}`;
  };

  const onDeleteRecommentAssetItem = async (itemId: string) => {
    const { status } = await new RecommendAssetService().deleteRecommendAssetItem(recommentAssetId, itemId);

    if (status !== HttpStatus.OK) {
      message.error('Add item failed.');
      return;
    }

    message.success('Add item success.');
    window.location.href = `${RouteNames.recommendAssetDetail}?id=${recommentAssetId}`;
  };

  const onClickItem = (assetId: string) => {
    window.location.href = RouteNames.assetDetail.replace(':id', assetId);
  };

  const onDeleteItem = (assetId: string) => {
    confirm({
      title: `Do you want to delete ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const itemData = recommendAssetItems.filter((x) => x.assetId === assetId)[0];
        const newData = recommendAssetItems.filter((x) => x.assetId !== assetId);
        setRecommendAssetItems(newData);
        if (itemData.id) {
          onDeleteRecommentAssetItem(itemData.id);
        }
      },
    });
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
      >
        <Card
          className='card-customize'
          title={
            <Space align='center'>
              <Typography.Title
                level={5}
                className='mb-0'
              >
                Recommend Asset Detail
              </Typography.Title>
            </Space>
          }
          extra={
            <Space align='center'>
              {
                recommentAssetId &&
                <Button
                  type='primary'
                  danger
                  size='large'
                  onClick={() => Delete(recommentAssetId)}
                >
                  Delete
                </Button>
              }
              <Button
                type='primary'
                htmlType='submit'
                size='large'
              >
                Save
              </Button>
            </Space>
          }
        >
          <div className='p-4'>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={12}
              >
                <Form.Item
                  name='name'
                  className='text-light w-100'
                  label='Recommend Name'
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input
                    className='w-100'
                    size='large'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='startDate'
                  className='text-light w-100'
                  label='Start Date'
                  rules={[{ required: true, message: 'Please input your startdate!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                    onChange={(dateValue) => form.setFieldsValue({ endDate: null })}
                    disabledDate={(current) => {
                      return current && current.isBefore(dayjs().startOf('day'));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='endDate'
                  className='text-light w-100'
                  label='End Date'
                  rules={[{ required: false, message: 'Please input your enddate!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                    disabledDate={(current) => {
                      var startDate = form.getFieldValue('startDate');
                      return current && current.isBefore(dayjs(startDate).startOf('day'));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider
              orientation='left'
              orientationMargin='0px'>List Asset</Divider>
            <Row>
              <Col style={{ paddingTop: 5, paddingRight: 5 }}>
                <Title level={5}>Asset Code : </Title>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='assetCode'
                  className='text-light w-100'
                  rules={[{ required: false }]}
                >
                  <Input
                    className='w-100'
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => onAddItem()}
                  size='large'
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row gutter={16}>
              {recommendAssetItems &&
                recommendAssetItems.map((data, index) => (
                  <Col>
                    <GalleryItem
                      index={index}
                      asset={data.asset}
                      onClickItem={onClickItem}
                      width='120px'
                      height='120px'
                      onDelete={onDeleteItem}
                      btnDelmarginRight={20}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </Card>
      </Form>
    </>
  );
}
