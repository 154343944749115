import {
  Card,
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Modal,
  Badge,
  message,
  InputNumber,
} from 'antd';
import Button from '../../components/General/Button';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OrderService } from '../../services/orderService';
import { HttpStatus } from '../../constant/responseStatus';
import { OrderModel, OrderItemModel } from '../../models/OrderModel';
import { OrderStatus } from '../../constant/statusConstants';
import { applicationUserModel } from '../../models/accountModel';
import { AssetType } from '../../constant/assetConstants';
import Title from 'antd/es/typography/Title';
import { RouteNames } from '../../components/Route/routeName';
import { DateFormat } from '../../constant/constants';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReceiptReport from '../../components/report/ReceiptReport/ReceiptReport';
import { BuyerTypeConstants } from '../../constant/buyerTypeConstants';

const { confirm } = Modal;
const { Paragraph, Text } = Typography;
export default function OrderDetail() {
  const [searchParams] = useSearchParams();
  const [orderId, setOrderId] = useState('');
  const [, setApplicationUser] = useState<applicationUserModel>({} as applicationUserModel);
  const [order, setOrder] = useState<OrderModel>({} as OrderModel);
  const [orderItems, setOrderItems] = useState<OrderItemModel[]>([]);
  const [afterDiscountTotal, setAfterDiscountTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setOrderId(id);
      onGetOrder(id);
    }
  }, [searchParams]);

  const onGetOrder = async (id: string) => {
    const { data, status } = await new OrderService().getId(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setOrder(data.order);
    setApplicationUser(data.applicationUser);
    setOrderItems(data.orderItems);
    if (data.order.specialDiscountTotal) {
      setAfterDiscountTotal(data.order.grossTotal - data.order.discountTotal - data.order.specialDiscountTotal);
    } else {
      setAfterDiscountTotal(data.order.grossTotal - data.order.discountTotal);
    }
  };

  const Cancel = (id: string) => {
    confirm({
      title: `Do you want to cancel order ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onCancel(id);
      },
    });
  };

  const Approve = (id: string) => {
    confirm({
      title: `Do you want to approve order ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onApprove(id);
      },
    });
  };

  const onCancel = async (id: string) => {
    const { status } = await new OrderService().updateCancel(id);

    if (status !== HttpStatus.OK) {
      message.error('Save order failed.');
      return;
    }

    message.success('Save order success.');

    onGetOrder(orderId);
  };

  const onApprove = async (id: string) => {
    const { status } = await new OrderService().updateApprove(id);

    if (status !== HttpStatus.OK) {
      message.error('Save order failed.');
      return;
    }

    message.success('Save order suucess.');

    onGetOrder(orderId);
  };

  const onSaveSpecialDiscount = async () => {
    const { status } = await new OrderService().updateSpecialDiscount(order.id, order.specialDiscountTotal!);

    if (status !== HttpStatus.OK) {
      message.error('Save order failed.');
      return;
    }

    message.success('Save order suucess.');

    navigate(`${RouteNames.orderDetail}?id=${orderId}`);
  };

  const onChangeSpecialDiscount = (val: number) => {
    const afterDiscountTotal = order.grossTotal - order.discountTotal;
    const afterSpecialDiscountTotal = afterDiscountTotal - val;
    const vat = afterSpecialDiscountTotal * (7 / 100);
    const net = afterSpecialDiscountTotal + vat;
    setAfterDiscountTotal(afterSpecialDiscountTotal);
    setOrder({ ...order, specialDiscountTotal: val, vatTotal: vat, netTotal: net });
  };

  return (
    <>
      {order && (
        <>
          <Card
            className='card-customize'
            title={
              <Space align='center'>
                <Typography.Title
                  level={5}
                  className='mb-0'>
                  Order Detail
                </Typography.Title>
              </Space>
            }
            extra={
              <Space align='center'>
                {order && orderItems && (
                  <>
                    <Divider type='vertical' />
                    {order.status === OrderStatus.Completed && (
                      <>
                        <PDFDownloadLink
                          document={
                            <ReceiptReport
                              order={order}
                              orderItem={orderItems}
                            />
                          }
                          fileName={`Receipt_${order.code}.pdf`}>
                          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Invoice/Receipt')}
                        </PDFDownloadLink>
                        <Divider type='vertical' />
                      </>
                    )}
                  </>
                )}
                <Paragraph className='mb-0 me-2'>Status : </Paragraph>
                {order.status === OrderStatus.Pending ? (
                  <>
                    <Badge
                      status='default'
                      text={"waiting for payment"}
                    />
                    <Button
                      type='primary'
                      text='Cancel'
                      danger
                      onClick={() => Cancel(orderId)}
                    />
                    <Button
                      type='primary'
                      text='Approve'
                      onClick={() => Approve(orderId)}
                    />
                  </>
                ) : order.status === OrderStatus.Completed ? (
                  <Badge
                    status='success'
                    text={order.status}
                  />
                ) : (
                  <Badge
                    status='error'
                    text={order.status}
                  />
                )}
              </Space>
            }>
            <div className='p-4'>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Order No</Col>
                <Col span={20}>: {order.code}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Order Date</Col>
                <Col span={20}>: {moment(order.orderDate).format(DateFormat.DateTime)}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Paid Date</Col>
                <Col span={20}>
                  :{' '}
                  {order.status === OrderStatus.Completed
                    ? moment(order.updatedInfo.at).format(DateFormat.DateTime)
                    : ''}
                </Col>
              </Row>
              <Divider
                orientation='left'
                orientationMargin='0px'
                plain>
                Customer Info
              </Divider>
              {order.buyerType === BuyerTypeConstants.individual && (
                <>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Type</Col>
                    <Col span={20}>: Individual</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Name</Col>
                    <Col span={20}>: {order.individualInfo.firstname + ' ' + order.individualInfo.lastname}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Email</Col>
                    <Col span={20}>: {order.individualInfo.email}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Phone</Col>
                    <Col span={20}>: {order.individualInfo.phone}</Col>
                  </Row>
                </>
              )}
              {order.buyerType === BuyerTypeConstants.company && (
                <>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Type</Col>
                    <Col span={20}>: Company</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Name</Col>
                    <Col span={20}>: {order.companyInfo.companyName}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Email</Col>
                    <Col span={20}>: {order.companyInfo.branch}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Customer Phone</Col>
                    <Col span={20}>: {order.companyInfo.taxId}</Col>
                  </Row>
                </>
              )}
              <Divider
                orientation='left'
                orientationMargin='0px'
                plain>
                Order Info
              </Divider>
              {order && (
                <>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Payment Method</Col>
                    <Col span={20}>: {order.paymentMethod}</Col>
                  </Row>
                  {
                    (order.paymentMethod === 'PaymentTerm' && order.orderExpireDate) && (
                      <Row
                        gutter={16}
                        className='mt-4'>
                        <Col span={4}>Payment Expire Date</Col>
                        <Col span={20}>: {moment(order.orderExpireDate).format(DateFormat.DateTime)}</Col>
                      </Row>
                    )
                  }
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Gross Total</Col>
                    <Col span={20}>
                      :{' '}
                      {order.grossTotal &&
                        order.grossTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Discount Code</Col>
                    <Col span={20}>: {order.discountCode ? order.discountCode : `-`}</Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>Discount Total</Col>
                    <Col span={20}>
                      :{' '}
                      {order.discountTotal &&
                        order.discountTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    {order.status === OrderStatus.Pending ? (
                      <>
                        <Col
                          span={4}
                          style={{ paddingTop: 10 }}>
                          Special Discount Total
                        </Col>
                        <Col span={4}>
                          <InputNumber
                            size='large'
                            min={0}
                            max={order.grossTotal - order.discountTotal}
                            className='w-100'
                            value={order.specialDiscountTotal}
                            onChange={(val) => onChangeSpecialDiscount(val!)}
                          />
                        </Col>
                        <Col>
                          <Button
                            type='primary'
                            text='Save Special Discount'
                            onClick={onSaveSpecialDiscount}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={4}>Special Discount Total</Col>
                        <Col span={20}>
                          :{' '}
                          {order.specialDiscountTotal &&
                            order.specialDiscountTotal.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>After Discount Total</Col>
                    <Col span={20}>
                      :{' '}
                      {afterDiscountTotal.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>VAT Total (7%)</Col>
                    <Col span={20}>
                      :{' '}
                      {order.vatTotal &&
                        order.vatTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={4}>
                      <Text strong>Net Total</Text>
                    </Col>
                    <Col span={20}>
                      :{' '}
                      <Text strong>
                        {order.netTotal &&
                          order.netTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                      </Text>
                    </Col>
                  </Row>
                </>
              )}
              <Divider
                orientationMargin='0px'
                orientation='left'>
                Assets
              </Divider>
              {orderItems &&
                orderItems.map((data, index) => (
                  <Row
                    align='bottom'
                    gutter={16}
                    className='mt-2'>
                    <Col>
                      {data.asset.assetType === AssetType.IMAGE ? (
                        <div style={{ position: 'relative', height: 100, width: 100 }}>
                          <img
                            src={data.asset.thumbnailImageUrl}
                            style={{
                              position: 'relative',
                              height: 100,
                              width: 100,
                              objectFit: 'cover',
                              borderRadius: 5,
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ position: 'relative', height: 100, width: 100 }}>
                          <img
                            src={data.asset.thumbnailVideoCoverUrl}
                            style={{
                              position: 'relative',
                              height: 100,
                              width: 100,
                              objectFit: 'cover',
                              borderRadius: 5,
                            }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col span={16}>
                      <Title level={5}>{data.asset.code + ' ' + data.asset.name}</Title>
                      <div>Standard License : {data.standardLicenseName}</div>
                      <div>Extended License : {data.extendedLicenseName}</div>
                    </Col>
                    <Col
                      span={6}
                      className='text-right'>
                      <Title level={5}>
                        Net Total (Include VAT) :{' '}
                        {data.netTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        {' ฿'}
                      </Title>
                    </Col>
                  </Row>
                ))}
            </div>
          </Card>
        </>
      )}
    </>
  );
}
